import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import Layout from "components/Layout";
import TranscriptCard from "components/TranscriptCard";

const TranscriptTitle = styled("h1")`
    margin-bottom: 1em;
`

const TranscriptGrid = styled("div")`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.5em;

    @media(max-width: 1050px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5em;
    }

    @media(max-width: ${dimensions.maxwidthMobile}px) {
        grid-template-columns: 1fr;
        grid-gap: 2.5em;
    }
`

const Transcripts = ({ transcripts, meta }) => (
    <>
        <Helmet
            title={`Transcripts | Carolyn Stransky`}
            htmlAttributes={{ lang: "en" }}
            link={[
                {
                    rel: `canonical`,
                    href: `https://workwithcarolyn.com/transcripts`
                }
            ]}
            meta={[
                {
                    name: `description`,
                    content: `Written transcripts from talks given by Carolyn Stransky.`,
                },
                {
                    property: `og:title`,
                    content: `Transcripts | Carolyn Stransky`,
                },
                {
                    property: `og:description`,
                    content: `Written transcripts from talks given by Carolyn Stransky.`,
                },
                {
                    name: `og:image`,
                    content: `https://user-images.githubusercontent.com/26869552/80156613-a8c83a00-85c4-11ea-98a6-45ecad014bcd.png`,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:creator`,
                    content: meta.author,
                },
                {
                    name: `twitter:title`,
                    content: `Transcripts | Carolyn Stransky`,
                },
                {
                    name: `twitter:description`,
                    content: `Written transcripts from talks given by Carolyn Stransky.`,
                },
                {
                    name: `twitter:image`,
                    content: `https://user-images.githubusercontent.com/26869552/80156613-a8c83a00-85c4-11ea-98a6-45ecad014bcd.png`,
                }
            ].concat(meta)}
        />
        <Layout>
            <TranscriptTitle>
                Transcripts
            </TranscriptTitle>
            <TranscriptGrid>
                {transcripts.map((transcript, i) => (
                    <TranscriptCard
                        key={i}
                        title={transcript.node.transcript_title}
                        description={transcript.node.transcript_preview_description}
                        uid={transcript.node._meta.uid}
                    />
                ))}
            </TranscriptGrid>
        </Layout>
    </>
);

export default ({ data }) => {
    const transcripts = data.prismic.allTranscripts.edges;
    const meta = data.site.siteMetadata;
    if (!transcripts) return null;

    return (
        <Transcripts transcripts={transcripts} meta={meta}/>
    )
}

Transcripts.propTypes = {
    transcripts: PropTypes.array.isRequired,
    meta: PropTypes.object.isRequired,
};


export const query = graphql`
    {
        prismic {
            allTranscripts(sortBy: transcript_date_DESC) {
                edges {
                    node {
                        transcript_title
                        transcript_preview_description
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`

